'use client';

import { createContext } from 'react';
import type { GlobalModalContextType } from './global-modal.types';

export const GlobalModalContext = createContext<GlobalModalContextType>({
  openModal: () => {
    // Empty function placeholder
  },
  closeModal: () => {
    // Empty function placeholder
  },
  modals: [],
});
