import { ResponsiveImage, type ResponsiveImageProps } from '@vcc-package/media';
import { Sources } from '@vcc-www/utils/getMediaSources';
import Video from '@vcc-www/video';
import React from 'react';
import { ASPECT_RATIO } from '../media-gallery.constants';
import { ResponsiveAspectRatio } from '../media-gallery.module';
import styles from '../media-gallery.module.css';

export const MediaGalleryViewSliderItem = ({
  imageFallbackUrl,
  imageSources,
  isCurrent,
  loading,
  responsiveAspectRatio,
  videoCtaAutoId,
  videoSourcesMp4,
}: {
  imageFallbackUrl?: string;
  imageSources: ResponsiveImageProps['images'];
  isCurrent: boolean;
  loading: 'lazy' | 'eager';
  responsiveAspectRatio?: ResponsiveAspectRatio;
  videoCtaAutoId: string;
  videoSourcesMp4?: Sources;
}) => {
  const imgRef = React.useRef<HTMLImageElement>(null);

  function onErrorImageFallback() {
    if (imgRef.current && imageFallbackUrl) {
      imgRef.current.src = imageFallbackUrl;
      imgRef.current.srcset = '';
    }
  }
  const hasVideo =
    videoSourcesMp4 && Object.values(videoSourcesMp4).some(Boolean);

  return (
    <div className={`${styles.sliderItem}`}>
      {hasVideo ? (
        <Video
          aspectRatio={responsiveAspectRatio || ASPECT_RATIO}
          poster={{
            default: imageSources.sm.src,
            fromM: imageSources.md?.src,
            fromL: imageSources.lg?.src,
          }}
          srcMp4={videoSourcesMp4}
          loading="lazy"
          playPauseButtonTabIndex={isCurrent ? 0 : -1}
          shouldAutoplay={isCurrent}
          shouldPause={!isCurrent}
          videoCtaAutoId={videoCtaAutoId}
        />
      ) : (
        <ResponsiveImage
          images={imageSources}
          alt={imageSources.sm.alt}
          onError={onErrorImageFallback}
          loading={loading}
          className="w-full h-full background-secondary"
          sizes={{ lg: '70vw' }}
        />
      )}
    </div>
  );
};
