import { useInView } from '@vcc-www/hooks';
import { useEffect, useRef } from 'react';

/**
 * Dedicated hook for to determine Media component mid point
 * Used in useMediaGalleryPointer hook and onClick behaviour
 *
 * @returns {function} handleSliderContainer - Identifies if user triggered an event on left/right side of container based on 'viewportHalfWidth'
 * @returns {boolean} inView - True when component is mounted
 * @returns {Ref} inViewRef - Wrapps entire 'Media' component
 * @returns {Ref} viewportHalfWidth - Determines mid point of 'Media' component
 */

export const useMediaGalleryViewport = ({
  isModalOpen,
  isWrappedInModal,
}: {
  isModalOpen?: boolean;
  isWrappedInModal: boolean;
}) => {
  const [inViewRef, inView] = useInView<HTMLDivElement>();
  const viewportHalfWidth = useRef<null | number>(null);

  useEffect(() => {
    if (isWrappedInModal && !isModalOpen) return;

    const { clientWidth } = inViewRef?.current ?? {};
    if (clientWidth) {
      const currentViewWidth = clientWidth;
      viewportHalfWidth.current = currentViewWidth / 2;
    }
  }, [inViewRef, isModalOpen, isWrappedInModal]);

  const handleSliderContainer = (clientXPosition: number) => {
    if (!viewportHalfWidth.current) {
      console.error('viewportHalfWidth has no value');
      return null;
    }

    const onLeftHalfViewport = viewportHalfWidth?.current > clientXPosition;
    const onRightHalfViewport = viewportHalfWidth?.current < clientXPosition;

    return {
      onLeftHalfViewport,
      onRightHalfViewport,
    };
  };

  return {
    handleSliderContainer,
    inView,
    inViewRef,
    viewportHalfWidth,
  };
};
