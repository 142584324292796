import { Icon, type IconName } from '@volvo-cars/react-icons';
import type { ReactNode } from 'react';

type FulfillmentInformationProps = {
  fulfillmentIcon: {
    type: IconName;
  };
  fulfillmentInfo?: ReactNode;
};

export function FulfillmentInformation({
  fulfillmentIcon,
  fulfillmentInfo,
}: FulfillmentInformationProps) {
  return (
    <div className="w-full mt-16">
      <div className="flex items-center">
        <Icon
          size={40}
          icon={fulfillmentIcon.type}
          className="rounded border-secondary bg-secondary mr-16 p-8"
        />

        {typeof fulfillmentInfo === 'string' ? (
          <p>{fulfillmentInfo}</p>
        ) : (
          fulfillmentInfo
        )}
      </div>
    </div>
  );
}
