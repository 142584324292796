'use client';

import { Dialog } from '@vcc-package/overlays';
import React, { useContext, useReducer } from 'react';
import { GlobalModalContext } from './global-modal.context';
import type {
  Actions,
  GlobalModalProviderProps,
  GlobalModalState,
  OpenModalProps,
} from './global-modal.types';

const initialState: GlobalModalState = {
  modals: [],
  current: {
    modalId: '',
    title: '',
    children: null,
  },
};

function globalModalReducer(state: GlobalModalState, action: Actions) {
  switch (action.type) {
    case 'openModal':
      return {
        current: action.payload,
        modals: [...state.modals, action.payload],
      };
    case 'closeModal':
      return {
        current: {
          modalId: '',
          title: '',
          children: null,
        },
        modals: state.modals.filter(
          (modal) => modal.modalId !== action.payload,
        ),
      };
    default:
      return state;
  }
}

export function useModal() {
  return useContext(GlobalModalContext);
}

export function GlobalModalProvider({ children }: GlobalModalProviderProps) {
  const [state, dispatch] = useReducer(globalModalReducer, initialState);

  function openModal({ modalId, title, size, children }: OpenModalProps) {
    dispatch({
      type: 'openModal',
      payload: { modalId, title, children, size },
    });
  }

  function closeModal(modalId: string) {
    dispatch({
      type: 'closeModal',
      payload: modalId,
    });
  }

  const { modals, current } = state;

  const showModal = modals.length > 0;

  return (
    <GlobalModalContext.Provider value={{ ...state, openModal, closeModal }}>
      <Dialog
        open={showModal}
        onClose={() => closeModal(state.current.modalId)}
        title={current?.title}
        size={current?.size}
      >
        {current?.children}
      </Dialog>
      {children}
    </GlobalModalContext.Provider>
  );
}
