import { DEPLOY_ENV } from '@config';
import type { RegionCode, SiteSlug } from '@volvo-cars/market-sites';
import { getMarketSite, isValidSiteSlug } from '@volvo-cars/market-sites';
import type { ListingPriceOptions } from './types';

export function maskPhoneNumber(phoneNumber: string) {
  return `${phoneNumber.slice(0, phoneNumber.length / 2 - 1)}***${phoneNumber.slice(phoneNumber.length / 2 + 2)}`;
}

export function isNotUndefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function getPurchaseInformationFromSalesChannel({
  requiredDictionaryItems,
  isPriceEnabled,
  priceFormattedAmount,
  salesChannel,
  fromPrice,
}: ListingPriceOptions): string | undefined {
  if (isPriceEnabled && priceFormattedAmount) return priceFormattedAmount;

  if (fromPrice && salesChannel !== 'retailer') {
    return `${requiredDictionaryItems.from} ${fromPrice.formattedAmount}`;
  }

  switch (salesChannel) {
    case 'app':
      return requiredDictionaryItems.app;
    case 'retailer':
      return requiredDictionaryItems.retailer;
    case 'online':
      return priceFormattedAmount || '';
    default:
      return '';
  }
}

export function isImageFile(src: string) {
  if (src.startsWith('[') && src.endsWith(']')) {
    return false;
  }

  if (!isValidURL(src)) {
    return false;
  }

  const imageExtensions = ['.png'];
  const url = new URL(src);
  const pathname = url.pathname;
  const fileExtension = pathname.slice(pathname.lastIndexOf('.')).toLowerCase();
  return imageExtensions.includes(fileExtension);
}

function isValidURL(url: string) {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}

export function debounce(func: () => void, timeout = 300) {
  let timer: NodeJS.Timeout;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func();
    }, timeout);
  };
}

export function formatRegionCode(regionCode: RegionCode) {
  switch (regionCode) {
    case '001':
      return 'International';
    default:
      return regionCode;
  }
}

export function getVehicleAgeInMonths(structureWeek: number) {
  if (!structureWeek) {
    return;
  }

  const structureWeekString = structureWeek.toString();
  const year = Number.parseInt(structureWeekString.slice(0, 4), 10);
  const week = Number.parseInt(structureWeekString.slice(4, 6), 10);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const vehicleMonth = Math.ceil(week / 4.33);

  const vehicleAgeInMonths =
    (currentYear - year) * 12 + (currentMonth - vehicleMonth);

  return vehicleAgeInMonths;
}

export function getProductNameFromPath(pathname: string) {
  const parts = pathname.split('/');
  return parts.at(3) === 'additionals' ? parts.at(4) : '';
}

export function validSiteSlug(siteSlug: string) {
  const isValid = isValidSiteSlug(siteSlug);
  const isMaster = siteSlug === 'master';
  return isValid && !isMaster ? siteSlug : 'intl';
}

export function parseCollectionHandle(collectionHandle: string) {
  return collectionHandle
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('');
}

export function getFlagEmoji(countryCode: string) {
  return countryCode
    ?.toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt(0)));
}

export function getCampaignPageMarketSite(siteSlug: SiteSlug, isProd: boolean) {
  const isMasterSiteSlug = siteSlug === 'master';

  const validatedSiteSlug = isMasterSiteSlug && isProd ? 'intl' : siteSlug;
  const marketSiteData = getMarketSite(validatedSiteSlug);
  const locale = isMasterSiteSlug ? 'en' : marketSiteData.locale;
  const { regionCode = '001' } = marketSiteData;

  return {
    locale,
    regionCode,
    siteSlug: validatedSiteSlug,
  };
}

export function getMarketSiteWithTestMarket(siteSlug: SiteSlug | string) {
  if (siteSlug === 'zz' && DEPLOY_ENV !== 'prod') {
    return {
      locale: 'en-TL',
      regionCode: 'ZZ' as RegionCode,
      siteSlug: 'zz' as SiteSlug,
    };
  }

  const validatedSiteSlug = validSiteSlug(siteSlug);

  const marketSiteData = getMarketSite(validatedSiteSlug);
  const locale = marketSiteData.locale;
  const regionCode = marketSiteData.regionCode;

  return {
    locale,
    regionCode,
    siteSlug: validatedSiteSlug,
  };
}
